<!-- 注册页面 -->
<template>
  <div class="register-box">
    <van-nav-bar
      title=""
      left-text=""
      class="black_bg background"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-tabs v-model="active">
      <van-tab title="短信验证码登录">
        <div class="form">
          <div class="international_code">
            <van-field
              readonly
              clickable
              is-link
              class="black_bg background"
              name="picker"
              :value="value"
              placeholder="点击选择国家"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
          </div>
          <div class="phone">
            <span class="phone_number white_color"
              >+ {{ internationalCode }}</span
            >
            <van-form ref="form" validate-first>
              <van-field
                v-model="tel"
                class="black_bg background"
                :rules="[{ pattern, message: '请输入正确内容' }]"
                type="tel"
                placeholder="请输入手机号码"
              />
            </van-form>
            <!-- <van-field v-model="tel"  class="black_bg" type="tel" placeholder="请输入手机号码" /> -->
          </div>
          <div class="verify-code">
            <van-field
              v-model="code"
              class="black_bg background"
              placeholder="请输入验证码"
            />
            <img :src="CodeImg" @click="getGraphicalCode" alt="" />
            <div class="verify-code_right" v-if="CodeImg == ''">
              <span class="white_color" @click="getGraphicalCode"
                >获取图形验证码</span
              >
            </div>
          </div>
          <div class="verify-code">
            <van-field
              v-model="verifyCode"
              maxlength="6"
              class="black_bg background"
              placeholder="请输入验证码"
            />
            <div class="verify-code_right">
              <!-- <span class="white_color" @click="codetxt1=='获取验证码'?getVerification():''">{{codetxt1}}</span> -->
              <span
                class="white_color"
                @click="codetxt == '获取验证码' ? getVerification() : ''"
                >{{ codetxt }}</span
              >

              <!-- <span class="white_color">获取验证码</span> -->
            </div>
          </div>
          <div class="button">
            <van-button :type="buttonType" size="large" @click="loginTel"
              >登录</van-button
            >
          </div>
        </div>
      </van-tab>
      <van-tab title="邮箱登录">
        <div class="form">
          <div class="phone">
            <span class="phone_number emailar white_color">邮箱地址</span>
            <van-form ref="form" validate-first>
              <van-field
                v-model="email"
                class="black_bg background"
                :rules="[{ emailPattern, message: '请输入邮箱地址' }]"
                type="text"
                placeholder="请输入邮箱地址"
              />
            </van-form>
            <!-- <van-field v-model="email"  class="black_bg" placeholder="请输入邮箱地址" /> -->
          </div>
          <div class="tuijianma">
            <div class="eye">
              <img
                :src="passwordType === 'password' ? closeEye : openEye"
                @click="showPwd"
              />
            </div>
            <van-field
              v-model="telyou"
              :type="passwordType"
              placeholder="请输入密码"
              class="black_bg background"
            />
            <div class="tuijianma_right">
              <!-- <span class="white_color">忘记密码</span> -->
              <span
                ><router-link
                  :to="{ path: '/forgetEmail' }"
                  class="white_color"
                  style="font-size: 14px"
                  >忘记密码</router-link
                ></span
              >
            </div>
          </div>
          <div class="notepass">
            <van-icon name="warning" color="#f07060" />
            <span style="color: #ee715f; font-size: 12px; line-height: 63px"
              >6-20位（支持数字、字母、或特殊符号）</span
            >
          </div>
          <div class="button button_blue">
            <!-- 邮箱登录 -->
            <van-button :type="buttonType" size="large" @click="loginEma"
              >登录</van-button
            >
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div class="loginoregis">
      <router-link :to="{ path: '/login' }"
        ><span style="color: #fff">密码登录</span></router-link
      >
      <router-link :to="{ path: '/register' }"
        ><span style="color: rgba(100, 158, 254, 1)"
          >立即注册</span
        ></router-link
      >
    </div>
  </div>
</template>

<script>
import closeEye from '@/assets/images/close_eye.png'; //闭眼
import openEye from '@/assets/images/open_eye.png'; //睁眼
import { Toast } from 'vant';
// import {login} from "../../api/index";
export default {
  data() {
    return {
      active: 0,
      value: '中国 (大陆)',
      columns: ['中国 (大陆)'],
      showPicker: false,
      internationalCode: '86',
      verifyCode: '',
      tuiJianMa: '',
      tuiJianMayou: '',
      closeEye: closeEye,
      openEye: openEye,
      passwordType: '',
      buttonType: 'primary',
      tel: '',
      email: '',
      password: '',
      telyou: '',

      codetxt: '获取验证码',
      code: '', // 图形验证码
      CodeImg: '', // 图片验证码
      emailCode: '', // 图片验证码  邮箱
      emailCodeImg: '', // 图片验证码
      emailPattern:
        /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/, //正则验证邮箱
      pattern: /^1[3-9]\d{9}$/, // 正则验证手机号
    };
  },

  components: {},

  computed: {},

  // mounted: {},

  methods: {
    // 验证码登录
    loginTel() {
      let params = {
        tel: this.tel,
        checkCode: this.verifyCode,
      };
      this.$api.loginTel(params).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('loginType', 1);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('nickName', res.data.nickName);
          localStorage.setItem('proportionNum', res.data.proportionNum);
          this.$toast.success(res.msg);
          this.$router.push('/home');
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        }
      });
    },

    // 邮箱登录
    loginEma() {
      let from = {
        name: this.email,
        password: this.telyou,
      };
      // 邮箱登录
      this.$api.login(from).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('loginType', 2);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('nickName', res.data.nickName);
          localStorage.setItem('proportionNum', res.data.proportionNum);
          this.$toast.success(res.msg);

          this.$router.push('/home');
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        }
      });
    },

    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    onClickLeft() {
      this.$router.back();
    },

    // 获取验证码
    getVerification() {
      if (!this.tel) {
        this.$toast('请输入手机号');
        return;
      }
      let toastEml = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      // 当前输入手机号
      let params = {
        tel: this.tel,
        code: this.code,
      };
      this.$api.loginTU(params).then((res) => {
        if (res.code == 0) {
          // 调用记载中提示
          toastEml.clear();
          // this.$toast.success('验证码已发送')
          Toast.success({
            message: res.msg,
            forbidClick: true,
          });
          let n = 60;
          this.timer = setInterval(() => {
            this.codetxt = `${n--}s`;
            if (n == 0) {
              this.codetxt = '获取验证码';
              clearInterval(this.timer);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        }
      });
    },
    // 获取图片验证码
    getGraphicalCode() {
      let params = {
        tel: this.tel,
      };
      this.$refs.form
        .validate()
        .then(() => {
          this.$toast.success('获取成功');
          this.$api.getGraphicalCode(params).then((res) => {
            this.CodeImg = (URL || webkitURL).createObjectURL(
              new Blob([res], { type: 'img/jpeg' }),
            );
          });
        })
        .catch(() => {
          this.$toast.fail('获取失败');
        });
    },
    // 获取邮箱图片验证码
    getEmailGraphicalCode() {
      let params = {
        email: this.email,
      };
      this.$refs.form
        .validate()
        .then(() => {
          this.$toast.success('获取成功');
          this.$api.getEmailGraphicalCode(params).then((res) => {
            this.emailCodeImg = (URL || webkitURL).createObjectURL(
              new Blob([res], { type: 'img/jpeg' }),
            );
          });
        })
        .catch(() => {
          this.$toast.fail('获取失败');
        });
    },
  },
};
</script>
<style lang="less" scoped>
.register-box {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  background: #000;
  width: 100%;
  height: 100vh;
  /deep/.van-nav-bar__arrow {
    color: #fff;
    font-size: 24px;
  }
  /deep/.van-dropdown-menu__title {
    color: #fff;
  }
  /deep/.van-tab {
    color: #d9d9d9;
    font-size: 14px;
    line-height: 26px;
  }
  /deep/.van-tab--active {
    color: #fff;
    font-weight: bold;
  }

  /deep/.van-tabs__line {
    width: 50%;
    border-radius: 0;
    height: 1px;
    background-color: #fff;
  }
  /deep/.van-tabs__nav--line {
    background-color: #000;
  }
  /deep/.van-tabs__wrap {
    margin: 0 22px;
  }
  /deep/.van-tabs--line .van-tabs__wrap {
    border-bottom: 1px solid rgba(37, 37, 37, 1);
  }
  .van-button--large {
    height: 40px;
  }
  .form {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    font-size: 14px;
    color: #c2c2c2;
    .international_code {
    }
    /deep/ .van-field__control {
      color: #fff !important;
    }
    .van-cell {
      padding: 10px 0;
    }
    .phone {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #252525;
      padding: 5px 0;
      .phone_number {
        display: flex;
        width: 18%;
        padding: 0 10px 0 5px;
        color: #eee;
      }
      .phone_number::after {
        content: ' ';
        display: flex;
        width: 1px;
        height: 26px;
        margin-left: 10px;
        background: #eeeeee;
      }
      .emailar {
        width: 40%;
      }
    }
    .verify-code {
      display: flex;
      border-bottom: 1px solid #252525;
      align-items: center;
      padding: 5px 0;
      .verify-code_right {
        display: flex;
        width: 48%;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          white-space: nowrap;
        }
        span::before {
          content: ' ';
          display: flex;
          width: 1px;
          height: 30px;
          margin-right: 10px;
          background: #eeeeee;
        }
      }
    }
    .tuijianma {
      display: flex;
      border-bottom: 1px solid #252525;
      align-items: center;
      padding: 5px 0;
      .tuijianma_right {
        display: flex;
        width: 48%;
        align-items: center;
        justify-content: space-between;
        span {
          display: flex;
          align-items: center;
        }
        span::before {
          content: ' ';
          display: flex;
          width: 1px;
          height: 30px;
          margin-right: 10px;
          background: #eeeeee;
        }
      }
    }
    .password {
      display: flex;
      border-bottom: 1px solid #252525;
      align-items: center;
      padding: 5px 0;
      span {
        display: flex;
        width: 38%;
        align-items: center;
      }
      span::before {
        content: ' ';
        display: flex;
        width: 1px;
        height: 30px;
        margin-right: 10px;
        background: #eeeeee;
      }
    }
    .button {
      margin-top: 30px;
      .van-button--primary {
        color: #fff !important;
        // background-color: rgba(51, 51, 51, 1) !important;
        background-color: #0754d3;
        border: 0;
      }
      .van-button--default {
        color: #fff !important;
        // background-color: rgba(51, 51, 51, 1) !important;
        background-color: #0754d3;
        border: 0;
      }
    }
  }
  .loginoregis {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
    margin: 5px 20px;
    a {
      color: #181818;
    }
  }
  .eye {
    display: flex;
    img {
      width: 17px;
      height: auto;
      padding: 0 20px;
    }
  }
  .notepass {
    font-size: 12px;
    margin-top: 10px;
    color: #f07060;

    span {
      margin-left: 10px;
    }
  }
}

.background {
  background-color: black;
}
</style>
